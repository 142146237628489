/* Font family */

body,
.btn {
  font-family: "Lato", sans-serif !important;
}
.bg-danger,.btn-danger {
  background: #dc252f !important;
}
.btn-danger{
  border-color: #dc252f !important;
}

/* Odds page */

.fs-14 {
  font-size: 14px;
}

.star-fill {
  color: #FFE248;
}

.owl-carousel {
  display: none; 
}

.owl-carousel.owl-loaded {
  display: block;
}
.carouselloader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.list-custom-small .dropdown {
  color: #1f1f1f !important;
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.page-content {
  min-height: 88vh;
}

.footer-bar-6 .circle-nav strong {
  background-image: linear-gradient(
    to bottom,
    #dc252f 0%,
    #b13037 100%
  ) !important;
}

.footer-bar-6 .circle-nav i {
  transform: translateY(-27px) translateX(0px);
  font-size: 40px !important;
}

.footer-bar-6 .circle-nav span {
  margin-top: -20px !important;
}

#footer-bar a i {
  font-weight: normal;
  font-size: 25px;
}

.refresh-count {
  display: block;
  font-size: 10px;
}

.refresh {
  position: fixed;
  bottom: calc(80px + (env(safe-area-inset-bottom)) * 1.1);
  right: 15px;
  height: 52px;
  background: #fff;
  width: 52px;
  background: #fff;
  text-align: center;
  line-height: 68px;
  border-radius: 50%;
  color: #dc252f;
  box-shadow: 0px 1px 6px #999;
  z-index: 99;
}

.footer-bar-6 .circle-nav u {
  display: none;
}

.refresh i {
  font-size: 30px;
}

.footer-bar-6 .circle-nav strong,
.footer-bar-6 .circle-nav u {
  height: 55px;
  width: 55px;
}

.page-title-clear {
  height: calc(50px + (env(safe-area-inset-top)) * 0.8) !important;
}

.bg-dardred-light {
  background: #dc252f;
  height: auto;
}

.btn i.fa-star {
  color: #fab81d;
}

.bg-yellow {
  background: #ffe248;
  color: #323030;
}

.btn.accordion-btn {
  color: #323030;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}

/* .menulist {
  margin-left: 50px;
  margin-top: 12px;
} */

.menulist li,.topmenu li {
  display: inline-block;
  margin-right: 15px;
  align-items: center;
}

.install-button {
  background-color: #86898C;
  color: #fff;
}

.download-image {
  display: flex;
  position: relative;
  width: 100%;
}

.browsers {
  width: 28px;
  height: 28px;
  margin: auto 1rem;
}

.topmenu li button {
  color: #fff!important;
}

.menulist li button.active {
  color: #18334c !important;
  background: #fff;
}

.menulist li a {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.menulist li.menu-right {
  float: right;
  margin-right: 50px;
}

.submenulist li.menu-right {
  float: right;
  padding-right: 5px;
}

.submenulist {
  background: #18334c;
  margin-bottom: 0;
  padding: 0 15px;
  height: 2rem;
}

.submenulist li {
  display: inline-block;
  padding: 8px 20px 8px 0;
}

.submenulist li a {
  color: #ffe248;
  font-size: 14px;
  font-weight: 700;
}

.submenulist li button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-size: 14px;
  color: #ffe248;
  font-weight: 700;
}

.submenulist li span {
  font-size: 14px;
  color: #ffe248;
  font-weight: 700;
  display:block;
}
.submenulist li span::after {
  display: block;
  content: ' ';
  width: 100%;
  border-bottom: 2px solid #ffe248;
  transform: scaleX(1.4) translateY(4px);
}

.accordion .btn-group button,
.accordion .btn-group button:active,
.accordion .btn-group button:focus {
  background: #fff !important;
  color: #323030 !important;
  font-weight: 600 !important;
}

.accordion .btn-group button:last-child {
  width: 80%;
}

.accordion .btn-group button:first-child {
  width: 5%;
  flex: 0 1 auto;
  margin: 0 0.5rem;
}

.accordion .card {
  box-shadow: 0px 2px 8px 0px rgba(81, 81, 81, 0.4) !important;
  margin-bottom: 8px !important;
}
.accordion .inner-table {
  height: 12px;
  width: 100%;
}

.accordion .content {
  margin: 20px 15px;
}
.accordion .owl-dots {
  line-height: 0;
}
.accordion .owl-dot {
  transform: scale(0.5, 0.5);
  margin-left: 0;
  margin-right: 0;
  height: 12px;
}

.third-header .submenulist li a {
  color: #dedede;
}

.third-header .submenulist {
  background: #888e99;
}

.third-header {
  top: 35px;
}

.second-header .submenulist {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100vw;
  max-width: 100%;
  white-space: nowrap;
}

.inner-table td .small {
  display: block;
  font-size: 11px;
  color: #999;
}
.inner-table td {
  padding: 2px 3px;
  border: 1px solid #dedede;
  text-align: center;
  line-height: 16px;
}
.inner-table tr:first-child td {
  padding: 8px 2px;
  border-top: 0 !important;
}
.inner-table.content tr:first-child td {
  border: 1px solid #ddd;
  color: 888e99;
  font-weight: 700;
}

.special {
  border-radius: 10px;
  padding: 8px 2px;
  line-height: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  left: 50%;
  margin-top: -30px;
  font-weight: bold;
  color: #fff;
  background: #1452cc;
}

.box-border {
  border: 1px solid #888e99;
  min-height: 160px;
  vertical-align: middle;
  padding: 2px;
  position: relative;
}

/* new box style */

.box {
  text-align: center;
  line-height: 13px;
  padding: 2px 0px !important;
  border-radius: 4px;
  color: #1452cc;
}
.box .small {
  display: block;
  color: #323030;
}
.box-label {
  min-height: 42px;
  line-height: normal;
  display: flex;
  align-items: center;
  word-break: break-all;
  color: #323030;
}
.box-heading [class*="col-"] {
  text-align: center;
  font-weight: 600;
}
.box-heading {
  border-bottom: 2px solid #86898C;
  max-height: 25px;
}
.slider-div {
  border-left: 1px solid #86898C;
}

.border-right-light {
  border-right: 1px solid #86898C;
}
.border-right-dark {
  border-right: 1px solid #86898C;
}
.text-primary {
  color: #1452cc !important;
}
.text-danger {
  color: #dc252f !important;
}
.flex-end {
  justify-content: flex-end !important;
}

/* login page */
.login-box {
  border: 2px solid #888e99;
  border-radius: 8px;
}
.login-box .bg-green-dark {
  background: #32a352 !important;
}
.login-logo {
  position: absolute;
  top: -43px;
  left: 50%;
  transform: translate(-50%);
}
.login-title {
  color: #323030;
  font-size: 16px;
  font-weight: 700;
}
.hide-collapse .rotate-180 {
  transform: rotate(0) !important;
}
.hide-collapse {
  display: none;
}
.hide-collapse.collapsed {
  display: block;
  text-transform: none !important;
  border-top: 1px solid #86898C !important;
  border-radius: 0;
}
.star-rating {
  color: #fab81d;
  display: inline-block;
  font-size: 13px;
}

.toast-custom {
  width: fit-content;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: normal;
}

/* Game page */
.header-logo-center .header-title.backbtn-header{
  width: 100%;
  margin-left: auto;
  left: unset;
}
.header-logo-center .header-title.backbtn-header .backicon{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.detail-section .status-caret{
  position: absolute;
  bottom: 10px;
  right: 8px;
}
.detail-section .card-header{
  background: #86898C;
  color: #fff;
  font-size: 13px;
  padding: 0.5rem 0.75rem;
  z-index: 2;
}
.header-bottom ul.submenu li{
  display: inline-block;
  margin-right: 20px;
}
.header-bottom{
  background: #86898C;
}
.header-bottom button.active,.header-bottom button.active:active,.header-bottom button.active:focus{
  background: #DEDEDE;
  color: #323030;
  padding: 2px 10px;
}
.header-bottom button,.header-bottom button:active,.header-bottom button:focus{
  color: #DEDEDE;
  text-decoration: none;
}

/* Game page */

@media (max-width: 330px) {
  .special-value {
    font-size: 10px;
  }
  .box-heading [class*="col-"] {
    font-size: 11px;
  }
  .box-heading {
    font-size: 11px;
  }
  .box-label,
  .box {
    font-size: 12px;
  }
}
