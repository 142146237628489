#navbar-tab {
  min-width: 100px;
}
#top-navbar {
  padding-top: 0.5rem;
}
#tab-wrapper1 {
  border-radius: 5px 0 0 5px;
  border: 1px solid #ffffff !important;
}
#tab-wrapper2 {
  border-radius: 0 5px 5px 0;
  border: 1px solid #ffffff !important;
}
.ticket {
  position: fixed;
  bottom: calc(120px + (env(safe-area-inset-bottom)) * 2.1);
  right: 15px;
  height: 52px;
  width: 52px;
  background: #fff;
  text-align: center;
  line-height: 68px;
  border-radius: 50%;
  color: #dc252f;
  box-shadow: 0px 1px 6px #999;
  z-index: 100;
}
.glow-circle {
  border: 4px solid #ffe248;
}
.match-length {
  width: 17px;
  height: 17px;
  background: #dc252f;
  border-radius: 50%;
  font-size: 10px;
  z-index: 100;
  position: fixed;
  bottom: calc(120px + (env(safe-area-inset-bottom)) * 2.1);
  right: 15px;
}
