.margin-top-16 {
  margin-top: 16px;
}

.btn-green {
  background-color: #32a352;
}

.table-header {
  width: 100%;
  display: block;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #86898c;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
}

.table-result-team-name {
  font-size: 14px;
  line-height: 1.6rem;
  font-weight: normal;
}

.table-rounded-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.table thead tr th {
  padding: 0px;
}

.table tbody tr td {
  vertical-align: inherit;
}

.table tbody tr th {
  text-align: start;
}

.table-content-col-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.result-table-content-date {
  font-weight: bolder;
  font-size: 13px;
  color: #323030;
}

.result-drawer {
  position: initial;
  margin-bottom: 0px !important;
}

.width-20p {
  width: 20%;
}

.width-18p {
  width: 18%;
}

.active-menu {
  color: #dc252f !important;
}

.news-title {
  color: #323030;
  text-transform: none;
  font-size: 13px;
  font-weight: bolder;
}

.news-content {
  font-size: 14px;
  line-height: 1.6rem;
}

/* ===========CASH PAGE============= */

.list-title {
  color: #323030;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.list-table-detail {
  width: 100%;
  color: #323030;
  font-size: 14px;
}

.list-table-detail-title {
  width: 100%;
}

.list-table-detail-title-30 {
  width: 30%;
}

.cash-value {
  text-align: end;
}

.cash-divider {
  height: 1px;
  display: block;
  background-color: #86898c;
}

.center-vertical {
  align-self: center;
}

/* ================================= */

/* ME PAGE */
.me-close-btn {
  font-size: 20px;
  color: #dc3545;
  position: absolute;
  text-align: center;
  width: 55px;
  right: 0px;
  top: 50%;
  margin-top: -13px;
}

.page-content {
  min-height: 88vh;
}

.footer-bar-6 .circle-nav strong {
  background-image: linear-gradient(
    to bottom,
    #dc252f 0%,
    #b13037 100%
  ) !important;
}

.footer-bar-6 .circle-nav i {
  transform: translateY(-27px) translateX(0px);
  font-size: 40px !important;
}

.footer-bar-6 .circle-nav span {
  margin-top: -16px!important;
}

#footer-bar a i {
  font-weight: normal;
  font-size: 25px;
}

.refresh-count {
  display: block;
  font-size: 10px;
}

.refresh {
  position: fixed;
  bottom: calc(80px + (env(safe-area-inset-bottom)) * 1.1);
  right: 15px;
  height: 52px;
  width: 52px;
  background: #fff;
  text-align: center;
  line-height: 68px;
  border-radius: 50%;
  color: #dc252f;
  box-shadow: 0px 1px 6px #999;
}

.footer-bar-6 .circle-nav u {
  display: none;
}

.refresh i {
  font-size: 30px;
}

.footer-bar-6 .circle-nav strong,
.footer-bar-6 .circle-nav u {
  height: 55px;
  width: 55px;
}

.page-title-clear {
  height: calc(50px + (env(safe-area-inset-top)) * 0.8) !important;
}

.bg-dardred-light {
  background: #dc252f;
  height: 50px;
}

.btn i.fa-star {
  color: #fab81d;
}

.btn.accordion-btn {
  color: #323030;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
}

.menulist {
  margin-left: 50px;
  margin-top: 12px;
}

.menulist li {
  display: inline-block;
  margin-right: 20px;
}

.menulist li a {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.submenulist {
  background: #18334c;
  margin-bottom: 0;
  padding: 0 12px;
}

.submenulist li {
  display: inline-block;
  padding: 8px 20px 8px 0;
}

.submenulist li a {
  color: #ffe248;
  font-size: 14px;
  font-weight: 700;
}

.accordion .btn-group button,
.accordion .btn-group button:active,
.accordion .btn-group button:focus {
  background: #fff !important;
  color: #323030 !important;
}
.accordion .btn-group button:last-child {
  width: 80%;
}
.accordion .btn-group button:first-child {
  width: 5%;
}

.active-odd {
  background-color: #fbfbfb;
}

.odd-table-heading {
  color: #323030;
  font-size: 12px;
}

.table thead th {
    border-bottom: 1px solid #86898c;
}

.odd-box {
  font-size: 14px;
  font-weight: 700;
  border: 0.5px solid #86898c;
  box-shadow: 0px 2px 2px #86898C !important;
  font-weight: bold;
}

.odd-box-selected {
  border: 2px solid blue;
}

.box-parent {
  border-top: 2px solid #86898c;
}

.special-value {
  border-radius: 4px;
  padding: 8px 1px;
  font-weight: bold;
  color: #fff;
  background: #1452cc;
  margin: 0 0.5px;
  font-size: 11px;
  position: absolute;
  margin-top: 10px;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
}
