.errorPage {
  background: #F5F5F5;
  width:100%;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.errorContent {
margin-top: 30px;
font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 22px;

text-align: center;
color: #323030;
}